:root {
    --primery: #000;
    --secondary: #fff;
    --background: #FAF9F9;
}



/* LOCOMOTIVE-SCROLL CSS STYLINGS.. */

html.lenis {
    height: auto
}

.lenis.lenis-smooth {
    scroll-behavior: auto
}

.lenis.lenis-smooth [data-lenis-prevent] {
    overscroll-behavior: contain
}

.lenis.lenis-stopped {
    overflow: hidden
}

.lenis.lenis-scrolling iframe {
    pointer-events: none
}





/* COMMAN CLASSES NO USE OF MEDIAQURIES */
/* all sections stylings */

.scrollbar-thumb {
    background-color: #000 !important;
    width: 12px !important;
    border-radius: 10px !important;
    cursor: grab !important;
}

.scrollbar-track-y {
    width: 12px !important;
    background-color: transparent !important;
}



section#home-section {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
}

.section_wrapers {

    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    overflow: hidden;
    /* background-color: yellow; */

}

/* typing section */
article {
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--primery);
}


/* banner section */
.banner_section {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.single_text_section {
    margin: 1rem;
}



/* reused compoents contianer */
.viewPort-container {
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background: #ffffff; */
    background: #f5f5f5;
    border-bottom: 10px solid #fff;
    border-radius: 20px;
    /* position: sticky;
    top: 5px; */
}




/* CUSTOM MOUSE STYLINGS */
.custom-cursor {
    position: fixed;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    background: black;
    border-radius: 50%;
    transition: transform .2s ease;
    transform: translate(-50%, -50%);
    pointer-events: none;
    z-index: 99;
    transition: all 0.1s ease;
    animation-duration: 0.3s;
    /* mix-blend-mode: difference; */
}




/*------ Mdeiaquries----- */
/* 600 */
/* MOBILR VIEW */
@media screen and (max-width: 767px) {



    .nav_list {
        margin-left: 15px;
    }

    .brand_image {
        height: 20px;
    }

    .brand_name {
        font-size: 20px !important;
    }


    /* LANDING PAGE [title]  */
    span.hover_span-1,
    .hover_span-2,
    .hover_span-3 {
        height: 45px !important;
        overflow: hidden;
    }




    .typoghrphy-h3::after {
        content: '|';
        position: absolute;
        right: 0;
        color: #000;
        /* background-color: var(--secondary) !important; */
        width: 110%;
        background-color: salmon;
        animation: types 3s steps(19) alternate infinite,
            line 1s infinite;
    }


    /* typing text */




    .typoghrphy-h2,
    .typoghrphy-h3 {
        font-size: 29px !important;
    }

    /* .circle-ball-3 {

        bottom: -30%;
        right: 7% !important;
        width: 35px !important;
        height: 35px !important;
    }

    .circle-ball-1 {
        top: -6px !important;
        left: 15% !important;
        width: 50px !important;
        height: 50px !important;
    }

    .circle-ball-4 {
        bottom: -14% !important;
        left: 10%;
    }

    .circle-ball-5 {
        width: 30px !important;
        height: 30px !important;
        right: 0;
        bottom: 5%;
    }

    .circle-ball-6 {
        width: 45px !important;
        height: 45px !important;
        top: 0;
        left: 0;
    }

    .circle-ball-7 {
        width: 30px !important;
        height: 30px !important;
        top: -46px;
        left: 50%;
    } */






    .title_1 {
        color: #000 !important;
        font-family: Poppins !important;
        font-size: 36px !important;
        font-style: normal !important;
        font-weight: 275 !important;
        line-height: normal !important;
    }

    .title_2,
    .title_span {
        font-family: Poppins !important;
        font-size: 40px !important;
        font-style: normal !important;
        font-weight: 700 !important;
        line-height: normal !important;
    }


    .banner_imgWraper {
        width: 310px !important;
    }





    .heading {
        font-size: 20px !important;
        text-align: center;
        /* margin-bottom: 21px;
        margin-top: 21px; */
        margin: 21px 0 21px 0;
    }

    /* BANNER SECTION */
    .banner-texts h1 {
        color: #000;
        letter-spacing: -0.015em;
        text-align: center;
        font-family: Poppins;
        font-size: 39px !important;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 0;
        font-family: Poppins;
    }

    button.lb-btn {
        border: none;
        outline: none;
        padding: 10px;
        border-radius: 20px;
        width: 90%;
        min-height: 64px;
        overflow: hidden;
        color: var(--secondary);
        font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
        background: linear-gradient(90deg, #A81CC9 -7.4%, #ED0F98 97.96%);
        font-style: 1.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 999;
    }


    /* REUSED SECTION */
    .inside_box_1 {
        width: 240px;
        height: 300px;
        /* border-radius: 88px 88px 41px 97px; */
        border-radius: 45px 79px 41px 72px;
        position: relative;
        z-index: 99;

    }

    .inside_box_2 {
        width: 240px;
        height: 300px;
        position: absolute;
        top: 4%;
        left: 4%;
        overflow: hidden;
        object-fit: cover;
        /* border-radius: 88px 88px 41px 97px; */
        border-radius: 45px 79px 41px 72px;
        z-index: 99;


    }

    .rght_box {
        width: 240px;
        height: 300px;
        position: relative;
        border-radius: 30% 20% 45% 10%;
    }

    .rght_box_inside {
        width: 240px;
        height: 300px;
        position: absolute;
        bottom: 4%;
        right: 1%;
        /* border-radius: 30% 20% 50% 10%; */
        border-radius: 15% 15% 50% 15%;
        overflow: hidden;
    }

    /* removing svg less than 600px devices.. */
    .strokeSVG {
        display: none;

    }

    /* footer stylings */
    .layer1 {

        position: absolute;
        bottom: 0;
        left: -22% !important;
        top: 33% !important;

    }

    /* FOOTER STYLINGS.. */
    .layer2 {
        position: absolute;
        bottom: 0;
        top: 57% !important;
        left: -25% !important;
        /* right: 21%; */
    }

    .footer_main {
        height: 560px !important;
    }

    .footer_head {
        text-align: center;
        font-size: 1.9rem !important;
    }

    /* right and left section viewport */



}



@media(max-width:430px) {


    /* landing page titles */
    .titles {
        color: #000;
        text-align: center;
        font-family: Poppins;
        font-size: 25px !important;
        font-style: normal;
        font-weight: 700 !important;
        line-height: normal;
    }

    span.hover_span-1,
    .hover_span-2,
    .hover_span-3 {
        height: 45px !important;
        overflow: hidden;
    }


    /* footer stylings */
    .layer1 {

        position: absolute;
        bottom: 0;
        top: 40% !important;
        left: -266% !important;

    }

    .layer2 {
        position: absolute;
        bottom: 0;
        top: 23% !important;
        left: -10% !important;

    }

    .footer_main {
        height: 560px !important;
    }
}

/* TAB VIEW */
@media screen and (max-width: 992px) {

    /* removing custom cursor */
    .custom-cursor {
        display: none;
    }



    .nav_list {
        margin-left: 2rem;
    }



    .article-container {
        padding: 2rem;
    }

    /* TYPING SECTION.. */
    .typoghrphy-h2,
    .typoghrphy-h3 {
        color: var(--primery);
        font-family: Poppins;
        font-style: normal;
        font-weight: 275;
        line-height: normal;
        z-index: 99;
        font-size: 45px;
    }

    

    .typoghrphy-h3::after {
        content: '|';
        position: absolute;
        right: -19px;
        color: var(--secondary);
        background-color: var(--primery);
        width: 110%;
        animation: types 3s steps(19) alternate infinite, line 1s infinite;
    }

    .landig_img_wraper {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 25px;
    }




    .title_1 {
        color: var(--primery);
        font-family: Poppins;
        font-size: 40px;
        font-style: normal;
        font-weight: 275;
        line-height: normal;
        z-index: 1;
    }

    .title_2 {
        font-family: Poppins;
        font-size: 100px;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        background: linear-gradient(90deg, #C519B7 0.23%, #E8119D 86.55%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        z-index: 1;
    }

    .title_span {
        background: linear-gradient(90deg, #AF1CC5 49.77%, #E8119D 96.88%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family: Poppins;
        /* font-size: 96px; */
        font-size: 40px !important;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }


    .banner-p {
        text-align: start;
        font-weight: 400 !important;
    }

    .reused-p {
        font-weight: 400 !important;
        color: var(--primery);
        text-align: center;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .banner-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .texts {
        align-items: center;
        margin-bottom: 25px;
    }

    .heading {
        font-size: 1.9rem;
        text-align: center;        
        margin: 21px 0 21px 0;
    }

    /* BANNER SECTION.. */
    .banner-texts h1 {
        color: var(--primery);
        letter-spacing: -0.015em;
        text-align: center;
        font-family: Poppins;
        font-size: 46px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 0;
        font-family: Poppins;
    }

    /*  REUSED SECTIONS */

    .inside_box_1 {
        border-radius: 70px 50px 0 60px;
        width: 280px;
        height: 338px;

        position: relative;
        z-index: 99;

    }

    .inside_box_2 {
        width: 280px;
        height: 338px;
        position: absolute;
        top: 5%;
        left: 5%;
        overflow: hidden;
        object-fit: cover;        
        z-index: 99;


    }

    /* ---------------- */
    .rght_box {
        width: 280px;
        height: 338px;
        position: relative;
        border-radius: 100px 46px 98PX 20PX;
        z-index: 99;
    }

    .rght_box_inside {
        width: 280px;
        height: 338px;
        position: absolute;
        bottom: 5%;
        right: 5%;
        /* border-radius: 150px 100px 200px 54px; */
        overflow: hidden;
        z-index: 99;

    }






}

/* LG */
@media (min-width:992px) {

    /* RESET LG DEVICE STYLINGS */

    /* landing page titles */
    span.hover_span-1,
    .hover_span-2,
    .hover_span-3 {
        height: 120px !important;
        overflow: hidden;
    }

    .titles {
        color: var(--primery);
        text-align: center;
        font-family: Poppins;
        font-size: 68px !important;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }



    /* typing section */
    article {
        margin: 0;
        height: 100vh;
        display: flex;
        align-items: center;
        overflow: hidden;
        /* optional */
        background-color: var(--primery);
    }


    .banner-custom_container {
        background-color: var(--background);
        border-radius: 50px;
        padding: 90px 0;
    }

    .custom_container {
        /* optional */
        background-color: var(--primery);

        border-radius: 50px;
        padding: 90px 0;
    }

    .nav_list {
        margin-left: 4rem !important;
    }



    .title_1 {
        font-size: 3rem !important;
        color: var(--primery);
        font-family: Poppins;

        font-style: normal;
        font-weight: 275;
        line-height: normal;
        z-index: 1;
    }

    .title_2,
    .title_span {
        font-size: 3.1rem !important;
        font-family: Poppins;
        /* font-size: 100px; */
        /* font-size: 40px; */
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        background: linear-gradient(90deg, #C519B7 0.23%, #E8119D 86.55%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        z-index: 1;
    }

    .typoghrphy-h2,
    .typoghrphy-h3 {
        font-size: 3rem !important;
    }

    .typoghrphy-h3::after {
        content: '|';
        position: absolute;
        right: -30px;        
        color: var(--secondary);       
        background-color: var(--primery);
        width: 110%;        
        animation: types 3s steps(19) alternate infinite,
            line 1s infinite;
    }



    .banner-container {
        display: flex;
        justify-content: end;
        align-items: center;
    }

    .reused-p {
        color: var(--primery);
        font-family: Poppins;
        font-size: 18px !important;
        font-style: normal !important;
        font-weight: 400 !important;
        line-height: normal !important;
    }


    .heading {
        font-size: 2.2rem;
    }


    /*BANNER SECTION..  */
    .banner-texts h1 {

        color: var(--primery);
        letter-spacing: -0.015em;
        text-align: center;
        font-family: Poppins;
        font-size: 80px !important;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 0;
        font-family: Poppins;

    }

    button.lb-btn {
        border: none;
        outline: none;
        padding: 10px;
        border-radius: 20px;
        width: 50%;
        min-height: 64px;
        overflow: hidden;
        color: var(--secondary);
        font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
        background: linear-gradient(90deg, #A81CC9 -7.4%, #ED0F98 97.96%);
        font-style: 1.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 999;
    }




    /* REUSESD COMPONENTS STYLINGSS... */
    .inside_box_1 {
        width: 320px;
        height: 420px;
        position: relative;
        z-index: 99;        
        border-radius: 61px 87px 58px 120px;

    }

    .inside_box_2 {
        width: 320px;
        height: 420px;
        position: absolute;
        top: 5%;
        left: 5%;
        overflow: hidden;
        object-fit: cover;
        /* border-radius: 100px 100px 58px 120px; */
        border-radius: 61px 87px 58px 120px;
        z-index: 99;


    }

    .inside_box_2 img {
        width: 100%;
        height: 100%;
        object-fit: cover;        
    }

    .rght_box { 
        width: 320px;
        height: 420px;
        position: relative;
        border-radius: 30% 20% 50% 10%;
        z-index: 99;
    }

    .rght_box_inside {
        /* width: 300px;
        height: 400px; */
        width: 320px;
        height: 420px;
        position: absolute;
        bottom: 5%;
        right: 5%;
        border-radius: 150px 100px 200px 54px;
        overflow: hidden;
        z-index: 99;
    }

    .rght_box_inside img {
        width: 100%;
        height: 100%;
        /* filter: grayscale(100%); */
    }




    /* footer stylings. */
    .layer1 {

        position: absolute;
        /* bottom: 0;
        right: -13% !important; */
        top: 32% !important;
        left: -2% !important;


    }

    .layer2 {
        position: absolute;
        bottom: 0 !important;
        /* left: -64% !important; */
        left: -26% !important;
        /* left: -8% !important; */
    }

    .footer_main {
        height: 560px !important;
    }




}

/* TAB */
@media screen and (min-width: 768px) {


    /* landing page */
    .titles {
        color: #000;
        text-align: center;
        font-family: Poppins;
        font-size: 46px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    span.hover_span-1,
    .hover_span-2,
    .hover_span-3 {
        height: 70px;
        overflow: hidden;
    }




    

    /* BANNER SECTION.. */
    button.lb-btn {
        border: none;
        outline: none;
        padding: 10px;
        border-radius: 20px;
        width: 50%;
        min-height: 64px;
        overflow: hidden;
        color: var(--secondary);
        font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
        background: linear-gradient(90deg, #A81CC9 -7.4%, #ED0F98 97.96%);
        font-style: 1.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 999;
    }






    /* FOOTER STYLINGS.. */
    .layer1 {

        position: absolute;
        bottom: 3%;
        left: -75%;
        /* bottom: 0;
        left: -22%; */

    }

    .layer2 {
        position: absolute;
        bottom: 0;
        top: 62%;
        left: -71%;
        /* top: 57%;
        left: -25%; */

    }

    .footer_main {
        height: 560px !important;
    }





}

/* MOBILE VIEWS */
@media screen and (max-width: 767px) {

    /* new landingpage */
    .titles {
        color: var(--primery);
        text-align: center;
        font-family: Poppins;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }


    .scroll-down {
        display: none;
    }

    /* redused components */
    /* leftside-box */
    .inside_box_1,
    .inside_box_2 {
        border-radius: 45px 79px 41px 72px;
        height: 300px;
        width: 240px;
        z-index: 99;
    }

    /* rightside-box */
    .rght_box {
        border-radius: 30% 20% 45% 10%;
        height: 300px;
        width: 240px;
        position: relative;
    }

    .rght_box_inside {
        border-radius: 15% 15% 50% 15%;
        bottom: 4%;
        height: 300px;
        overflow: hidden;
        position: absolute;
        right: 1%;
        width: 240px;
    }
}





@media screen and (min-width: 1400px) {
    .layer1 {
        position: absolute;
        bottom: 0;
        top: 10% !important;
        left: 0% !important;
    }

    .layer2 {
        position: absolute;
        bottom: 0 !important;
        top: 52% !important;
        left: -28% !important;
        /* left: -48% !important; */

    }

    .tenvo_contacts,
    .footer_span {
        color: var(--secondary) !important;
        font-family: Poppins !important;
        font-size: 24px !important;
        font-style: normal !important;
        font-weight: 700 !important;
        line-height: normal !important;
    }

    .footer_content p {
        color: var(--secondary);
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .footer_head {
        color: var(--secondary) !important;
        font-family: Poppins !important;
        font-size: 50px !important;
        font-style: normal !important;
        font-weight: 700 !important;
        line-height: normal !important;
    }
}


@media screen and (min-width: 1200px) {
    .titles {
        color: var(--primery);
        text-align: center;
        font-family: Poppins;
        font-size: 80px !important;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
}



/*---- important ----*/
/* xxl full hd stylings..1920 */
@media screen and (min-width: 1400px) {

    /* landing page starts */



    .title_1 {
        color: var(--primery);
        font-family: Poppins;
        font-size: 80px !important;
        font-style: normal;
        font-weight: 275 !important;
        line-height: normal;
    }

    .title_2 {
        font-family: Poppins;
        font-size: 80px !important;
        font-style: normal;
        font-weight: 700 !important;
        line-height: normal;
    }






    /* TYPING SECTION STYLINGS STARTS */
    .article {

        height: 100vh;        
        display: flex;
        align-items: center;
    }

    .typoghrphy-h2 {
        color: var(--primery);
        text-align: center;
        font-family: Poppins;
        font-size: 80px !important;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .typoghrphy-h3 {
        color: var(--primery);
        font-family: Poppins;
        font-size: 80px !important;
        font-style: normal;
        font-weight: 700 !important;
        line-height: normal;
    }

    .typoghrphy-h3::after {
        content: '|';
        position: absolute;
        right: -46px;
        color: var(--secondary);
        background-color: var(--primery);
        width: 110%;

        animation: types 3s steps(19) alternate infinite, line 1s infinite;
    }

    .article-container {
        width: 1245.468px;
        height: 573.14px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    /* TYPING SECTION STYLINGS ENDS */


    /* ---BANNER STYLINGS STARTS */
    .custom_container {
        width: 1246.525px;
        height: 700.214px;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 119px;
        background: var(--primery);
    }

    .banner-custom_container {
        width: 1246.525px;
        height: 700.214px;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 119px;
        background: var(--background);
    }

    .title_span {
        background: linear-gradient(90deg, #AF1CC5 49.77%, #E8119D 96.88%) !important;
        background-clip: text !important;
        -webkit-background-clip: text !important;
        -webkit-text-fill-color: transparent !important;
        font-family: Poppins !important;
        font-size: 80px !important;
        font-style: normal !important;
        font-weight: 700 !important;
        line-height: normal !important;
    }

    .banner-p {
        color: var(--primery);
        font-family: Poppins;
        font-size: 24px !important;
        font-style: normal;
        /* font-weight: 600 !important; */
        line-height: normal !important;
        text-wrap: unset !important;
    }

    /* ---BANNER STYLINGS ENDS */






    /* REUSED COMPONENTS STYLINGS  */
    /* **** */
    /* ---LEFT SECTION STYLINGS STARTS */


    .inside_box_1 {
        width: 400.989px;
        height: 484.1px;
        border-radius: 100px 100px 58px 120px;

    }

    .inside_box_2 {
        width: 400.989px;
        height: 484.1px;
        flex-shrink: 0;
    }

    .heading {
        color: #1B1B1B !important;
        font-family: Poppins !important;
        font-size: 50px !important;
        font-style: normal !important;
        font-weight: 700 !important;
        line-height: normal !important;
    }

    .reused-p {
        color: var(--primery);
        font-family: Poppins;
        font-size: 24px !important;
        font-style: normal !important;
        font-weight: 400 !important;
        line-height: normal !important;
    }

    /* ---LEFT SECTION STYLINGS ENDS */

    /* RIGHT SECTION STYLING STARTS */
    .rght_box {
        width: 425.615px;
        height: 513.83px;
        flex-shrink: 0;
        border-radius: 216px 100px 150.57px 54px;
        background: linear-gradient(335deg, #00BBD4 5.06%, rgba(22, 105, 156, 0.00) 36.68%);
    }

    .rght_box_inside {
        width: 425.615px;
        height: 513.83px;
        flex-shrink: 0;
        border-radius: 100px 100px 180px 54px;
    }

    /* RIGHT SECTION STYLING ENDS */




    /* SVG POSITIONINGS STARTS.. */
    .strokeSVG {
        top: 17% !important;
        left: -12% !important;
        transform: scaleX(1.1);
        height: 3239px;
    }

    /* SVG POSITIONINGS STARTS.. */




    /* FOOTER SETCTION STYLINGS STARTS... */
    .layer1 {
        position: absolute;
        bottom: -23%;
        left: 0%;
    }

    /* FOOTER SETCTION STYLINGS ENDS... */
    /* xxl full hd stylings..1920 ENDS */
}




/* typing text section styling starts */
.text-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.typoghrphy-h2 {
    color: var(--secondary);
    font-family: Poppins;
    font-style: normal;
    font-weight: 275;
    line-height: normal;
    z-index: 99;
}

.typoghrphy-h3 {
    color: var(--secondary);
    font-family: Poppins;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    position: relative;
    z-index: 99;

}














@keyframes types {
    to {
        width: 0;
    }

    from {
        width: 110%;
    }
}

@keyframes line {
    50% {
        color: transparent;
    }
}

/* typing text section styling ends */







/* BANNER SECTION STYLING STARTS */

.rounded-div-wrapper {
    width: 100%;
    height: 100px;
    /* background-color: transparent; */
    position: relative;
    overflow: hidden;
    margin-top: -90px;
}

.rounded-div {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    width: 160%;
    height: 750%;
    border-radius: 50%;
    background-color: var(--secondary);
}

.banner {
    height: 100vh;
    width: 100%;

}

.banner-logo {
    height: 150px;
    width: 150px !important;
    /* background-color: salmon; */
}

.banner-logo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}




/* inside h1 &  */
span.inside-h1 {
    color:var(--primery);
    letter-spacing: -0.015em;
    text-align: center;
    font-family: Poppins;
    font-size: 80px !important;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 0;
    font-family: Poppins;
}

.description-span {
    color: #86868b;
    font-size: 24px;
    width: 700px !important;
    line-height: 1.3334133333;
    font-weight: 600;
    letter-spacing: .009em;
    /* font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif; */
    font-family: Poppins;
}

span.tenvo-span {
    color: var(--primery);


}




button.lb-btn:hover {
    background: linear-gradient(90deg, #ED0F98 -7.4%, #A81CC9 97.96%);
    transition: all 0.5s ease-in-out;
    animation: rotate624 0.7s ease-in-out both;
}




.inside-btn {
    color: var(--secondary);
    font-family: Poppins;
    font-style: 1.2rem;
}

/*  BUTTON HOVER ROTATE ANIMATION */

@keyframes rotate624 {
    0% {
      transform: rotate(0deg) translate3d(0, 0, 0);
    }
  
    25% {
      transform: rotate(3deg) translate3d(0, 0, 0);
    }
  
    50% {
      transform: rotate(-3deg) translate3d(0, 0, 0);
    }
  
    75% {
      transform: rotate(1deg) translate3d(0, 0, 0);
    }
  
    100% {
      transform: rotate(0deg) translate3d(0, 0, 0);
    }
  }

.button-wrapper {
    width: 450px !important;
    /* background-color: yellow; */
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
}

span.tenvo-span {
    font-weight: 700;
}

.h1-1 {
    color: var(--primery);
    font-family: Poppins;
    /* font-size: 96px; */
    font-size: 4rem;
    font-style: normal;
    font-weight: 275;
    line-height: normal;
}


.title_span {
    background: linear-gradient(90deg, #AF1CC5 49.77%, #E8119D 96.88%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: Poppins;
    /* font-size: 96px; */
    font-size: 3rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}


.h1-2 {
    font-family: Poppins;
    /* font-size: 96px; */
    font-size: 4rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    background: linear-gradient(90deg, #A81CC9 -7.4%, #ED0F98 97.96%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


.banner-p {
    color: var(--primery);
    font-family: Poppins;
    /* font-size: 20px; */
    font-style: normal;
    /* font-weight: 600 !important; */
    line-height: normal;
    text-wrap: balance;
    z-index: 1;
}




.banner_imgWraper {
    width: 400px;
}


.banner_img {
    width: 100%;
}

/* BANNER SECTION STYLING ENDS */




/*.... moved to mediaqureis .... */
/* REUSABLE COMPONENTS STYLINGS.. */
/* leftboxSide Component stylings starts */
/* .inside_box_1 {
    width: 300px;
    height: 400px;    
    border-radius: 88px 88px 41px 97px;
    position: relative;
    z-index: 99;

} */

.inside_box_2 {
    transition: all 0.2s ease-in-out;
}

.inside_box_2 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* filter: grayscale(100%); */
}



@keyframes bwMovement {
    0% {
        filter: grayscale(100%) translateX(-20px);
    }

    50% {
        filter: grayscale(100%) translateX(20px);
    }

    100% {
        filter: grayscale(100%) translateX(-20px);
    }
}




.inside_box_2:hover {

    position: absolute;
    top: 10%;
    left: 10%;


}



.heading {
    color: #1B1B1B;
    font-family: Poppins;
    /* font-size: 2.2rem; */
    font-style: normal;
    font-weight: 700;
    line-height: normal;

}

.description {
    color: var(--primery);
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

/* leftboxSide Component stylings ends */




/* ....moved to mediaquries... */
/* RightBoxside component stylings starts*/
/* .rght_box {
    width: 300px;
    height: 400px;
    position: relative;
    border-radius: 30% 20% 50% 10%;
} */

.rght_box_inside {
    transition: all 0.2s ease-in-out;
}

.rght_box_inside:hover {
    position: absolute;
    bottom: 10%;
    right: 10%;
    /* box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.3); */

}

.rght_box_inside img {
    width: 100%;
    height: 100%;
}

/* RightBoxside component stylings ends*/






/* ....svg stylings starts.... */
.svg-wraper {
    width: 100%;
    height: 100%;
    /* background-color: #6c9ebb45; */
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}

.child-wraper {
    width: 100%;
    height: 100%;
    /* background-color: #6c9ebb45; */
    position: relative;
}








/* footer section stylings starts */
section.sticky-footer {
    position: sticky;
    top: 0;
}

.footer_head {
    color: var(--secondary);
    font-family: Poppins;
    font-size: 3rem;
    /* font-size: 96px; */
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.footer_content {
    display: flex;
    flex-direction: column;
    /* justify-content: unset; */
}

.footer_span {
    color: var(--secondary);
    font-family: Poppins;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    z-index: 1;

}

.footer_content p {
    color: var(--secondary);
    font-family: Poppins;
    /* font-size: 32px; */
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    z-index: 1;
}


.footer_content a {
    text-decoration: none;
    z-index: 1;
}

.bottom_footer {
    background: #5D117F;
    position: absolute;
    bottom: 0;
    z-index: 2;
    width: 100%;
}

.copyright-text {
    color: var(--secondary);
    font-family: Poppins;
    /* font-size: 32px; */
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.layer1 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.layer2 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.footer_main {
    overflow: hidden;
    height: 100vh !important;
}

.custom_footer {
    height: 100vh;
    display: flex;
    align-items: center;
}

/* footer section stylings ends */



/* banner testing stylings */
