nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
}



/* Brand logo stylings... */
/* .brand_log {
    position: relative;
    background-color: #fff;
} */

 .brand_log a {
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
 }

.brand_name {    
    left: 41%;

    color: #000;
    font-family: Poppins;
    font-size: 1.8rem;
    /* font-size: 48px; */
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}





/* STYLING UL & LI */
.nav_ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    align-items: center;
}

.nav_list a {
    text-decoration: none;
    color: #000;
    font-family: Poppins;
    /* font-size: 24px; */
    font-style: normal;
    font-weight: 275;
    line-height: normal;
}
.nav_list a:hover{
    color: #C519B7;
}
.active-list a {
    color: green;
    text-decoration: none;    
    font-family: Poppins;
    /* font-size: 24px; */
    font-style: normal;
    font-weight: 275;
    line-height: normal;
}
