/* second column stylings.. */
.landingPage_text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.titles {
    z-index: 99;
    display: flex;
    /* overflow: hidden;
    height: 100px; */
}

.demo-div-1,
.demo-div-2,
.demo-div-3 {
    transition: all ease-out 0.5s;
}

/* typography---[CONNECT,] */
.hover_span-1:hover .demo-div-1 {
    transform: translateY(-100%);
    color: #ca1995;
}

/* typography---[CREATE,] */
.hover_span-2:hover .demo-div-2 {
    transform: translateY(-100%);
    color: #ca1995;
}

/* typography---[CATELYZE,] */
.hover_span-3:hover .demo-div-3 {
    transform: translateY(-100%);
    color: #ca1995;
}

span.hover_span-1,
.hover_span-2,
.hover_span-3 {
    /* height: 100px; */
    overflow: hidden;
}


/* 3RED BACKGROUND ANIMATION */



.area {
    position: absolute;
    width: 100%;
    height: 100vh;


}

.circles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

}

.circles li {
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: radial-gradient(174.86% 134.88% at 129.03% 15.73%, #b3b3b3, rgb(238, 234, 234), rgb(167, 163, 163), #b3b3b3);
    /* background:linear-gradient(-135deg,#b3b3b3,white); */
    /* background: radial-gradient(174.86% 134.88% at 129.03% 15.73%, #00bae2 0, #ffa9fa 11.29%, #fec5fb 46.35%, #00bae2 95.73%); */
    animation: animate 25s linear infinite;
    /* bottom: -150px; */
    bottom: 0;

}


.circles li:nth-child(1) {
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
}


.circles li:nth-child(2) {
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
}


.circles li:nth-child(3) {
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
}


.circles li:nth-child(4) {
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
}


.circles li:nth-child(5) {
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
}


.circles li:nth-child(6) {
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
}


.circles li:nth-child(7) {
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    /* animation-delay: 0s; */

}


.circles li:nth-child(8) {
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 2s;
    animation-duration: 4s;
}

.circles li:nth-child(9) {
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 5s;
}


.circles li:nth-child(10) {
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
}




@keyframes animate {

    0% {
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 50%;
        /* width: 20px; */
    }

    100% {
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }

}

/* 3RED BACKGROUND ANIMATION ENDS */

/* .title_1 {
    color: #000;
    font-family: Poppins;    
    font-size: 2.9rem;
    font-style: normal;
    font-weight: 275;
    line-height: normal;
    z-index: 1;
}

.title_2 {
    font-family: Poppins;
    font-size: 100px;
    font-size: 3rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    background: linear-gradient(90deg, #C519B7 0.23%, #E8119D 86.55%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    z-index: 1;
} */


/* scroll down .button_div stylings */
.button_div {

    flex-shrink: 0;
    border-radius: 100px;
    border: 1px solid #DEDEDE;
    background: #F6F6F6;
    cursor: pointer;
    z-index: 99;
}



/* ANIMATIONS */
/* @keyframes colorSlide {
    0% {
        background-position: 0% 100%;
    }

    20% {
        background-position: 0% 0%;
    }

    21% {
        background-color: #4e5559;
    }

    29.99% {
        background-color: #ffffff;
        background-position: 0% 0%;
    }

    30% {
        background-color: #4e5559;
        background-position: 0% 100%;
    }

    50% {

        background-position: 0% 0%;
    }

    51% {

        background-color: #4e5559;
    }

    59% {

        background-color: #ffffff;
        background-position: 0% 0%;
    }

    60% {

        background-color: #4e5559;
        background-position: 0% 100%;
    }

    80% {


        background-position: 0% 0%;
    }

    81% {


        background-color: #4e5559;
    }

    90%,
    100% {


        background-color: #ffffff;
    }

}

/* ball animation */





@keyframes nudgeMouse {
    0% {
        transform: translateY(0);
    }

    20% {
        transform: translateY(8px);
    }

    30% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(8px);
    }

    60% {
        transform: translateY(0);
    }

    80% {
        transform: translateY(8px);
    }

    90% {
        transform: translateY(0);
    }
}

.button_div img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    transition: 5s all ease-out;
}













/* scrolldown button animatoin */
.shake {
    animation: shake 0.5s ease-in-out infinite;

}

/* scrolldown button shaking animation */
@keyframes shake {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-15px);
    }

    60% {
        transform: translateY(-7px);
    }
}



/* NEW STYLINGS FOR LANDING PAGE */

/* .hover_span:hover {
    color:  #d639c1;
  
    animation: all 0.2s ease-in-out;
    
} */

button.scroll-down {
    border: none;
    outline: none;
    margin: 0 auto 0 auto;
    position: absolute;
    top: 8%;
    /* bottom: 6%; */
    z-index: 999;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background: linear-gradient(90deg, #A81CC9 -7.4%, #ED0F98 97.96%);
}

.scroll-down:hover {
    animation: jello-horizontal 0.9s both;
}

@keyframes jello-horizontal {
    0% {
        transform: scale3d(1, 1, 1);
    }

    30% {
        transform: scale3d(1.25, 0.75, 1);
    }

    40% {
        transform: scale3d(0.75, 1.25, 1);
    }

    50% {
        transform: scale3d(1.15, 0.85, 1);
    }

    65% {
        transform: scale3d(0.95, 1.05, 1);
    }

    75% {
        transform: scale3d(1.05, 0.95, 1);
    }

    100% {
        transform: scale3d(1, 1, 1);
    }
}